import { Injectable, Inject, SkipSelf } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';


@Injectable()
export class Msal2Guard  {
  constructor(private msalGuard: MsalGuard) { }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.msalGuard.canActivate(router, state);
  
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.msalGuard.canActivateChild(route, state);
 
  }
}
