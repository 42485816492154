import { Injectable, Inject, SkipSelf } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Oauth2Service } from '../services/login/oauth2.service';

@Injectable()
export class AuthGuard  {
  constructor(private authservice: Oauth2Service) { }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authservice.handleLoginAuthectication();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
