import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { MyaGlobalCacheService } from '../app/core/services/cache/mya-global-cache.service';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { ApproveesService } from './mya-approvee/services/approvees.service';
import { EventBusService, EmitEvent, MyApprovalEvents } from '../app/core/services/event-bus/event-bus.service'

@Injectable()
export class AppRoutingResolver  {

  constructor(private myaGlobalCacheService: MyaGlobalCacheService, private approveesService: ApproveesService, private eventBus: EventBusService) { }

  resolve(): any {
    if (window !== window.parent && !window.opener)
      return of(null);

    return !this.myaGlobalCacheService.getGlobalCache('UserRole')
      ? this.approveesService.getUserRole().pipe(switchMap(role => {
        this.myaGlobalCacheService.saveGlobalCache('UserRole', role)
        return this.initResolverData()
      })) : this.initResolverData();
  }

  private initResolverData() {
    return this.approveesService.getCoverageCountry();
  }
}

